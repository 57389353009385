<template>
  <b-card-code>
    <!-- modal -->
    <b-modal ref="new_asset_modal" hide-footer title="Create New Group" size="lg">
      <validation-observer ref="create-new-system-group" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewSystemGroupSubmit">
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|min:3"
              >
                <b-form-input
                  id="title"
                  v-model="createNewSystemGroup.title"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col>
            <b-form-group label="Description">
              <validation-provider
                #default="{ errors }"
                name="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="createNewSystemGroup.description"
                  debounce="500"
                  rows="3"
                  max-rows="5"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              Create
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- form -->
    <validation-observer ref="asset_form" #default="{ invalid }">
      <b-form
        class="auth-login-form"
        @submit.prevent="onAddNewButtonClick"
      >
        <!-- Asset Name -->
        <b-form-group label="Asset Name">
          <validation-provider
            vid="asset_name"
            #default="{ errors }"
            name="Asset Name"
            rules="required|min:3"
          >
            <b-form-input
              id="asset_name"
              v-model="assetAdd.asset_name"
              :state="errors.length > 0 ? false : null"
              name="Asset Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Location -->
        <b-form-group label="Asset Location">
          <validation-provider
            #default="{ errors }"
            name="Asset Location"
            rules="required|min:3"
          >
            <b-form-input
              id="asset-location"
              v-model="assetAdd.asset_location"
              :state="errors.length > 0 ? false : null"
              name="asset-location"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Value-->
        <b-form-group label="Asset Value">
          <validation-provider
            #default="{ errors }"
            name="Asset Value"
            rules="required"
          >
            <b-form-select
              v-model="assetAdd.asset_value"
              :options="assetAdd.asset_value_options"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>


        <!-- Asset Type-->
        <b-form-group label="Asset Category">
          <validation-provider
            #default="{ errors }"
            name="Asset Category"
            rules="required"
          >
            <b-form-select
              v-model="assetAdd.asset_type"
              :options="assetAdd.assetTypes"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Owner-->
        <b-form-group label="Asset Owner">
          <validation-provider
            #default="{ errors }"
            name="Asset Owner"
            rules="required"
          >
            <b-form-select
              v-model="assetAdd.asset_owner"
              :options="assetAdd.assetOwners"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Classification-->
        <b-form-group label="Classification">
          <validation-provider
            #default="{ errors }"
            name="Asset Classification"
            rules="required"
          >
            <b-form-select
              v-model="assetAdd.asset_classification"
              :options="assetAdd.assetClassifications"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- System Groups -->
        <b-form-group label="System Groups">
          <b-row>
            <b-col cols="8">
              <vue-autosuggest
                :suggestions="assetAdd.systemGroupSuggestions"
                :limit="5"
                v-model="assetAdd.currentSystemGroup"
                id="autosuggest__input"
                :input-props="assetAdd.systemGroupInputProps"
                @input="getSystemGroupsBySearchPhraseAndSetData"
                @selected="onSystemGroupSelected"
                :get-suggestion-value="getSystemGroupSuggestionValue"
              >
                <template
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <span style="{ display: 'flex', color: 'navyblue'}">{{
                    suggestion.item.title
                  }}</span>
                </template>
              </vue-autosuggest>
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-start
                  mt-75
                "
              >
                <template v-for="(value, i) in assetAdd.selectedSystemGroups">
                  <b-badge :key="i" variant="light-primary" class="mr-1">
                    <span>{{ value.title }}</span>
                    <feather-icon
                      @click="removeSystemGroup(value.title)"
                      icon="XCircleIcon"
                      class="cursor-pointer ml-50 text-danger"
                    />
                  </b-badge>
                </template>
              </div>
            </b-col>
            <b-col>
              <b-button @click="showModal" variant="primary"
                ><feather-icon
                  icon="PlusIcon"
                  class="cursor-pointer mr-50"
                />Create new group</b-button
              >
            </b-col>
          </b-row>
        </b-form-group>

        <!-- Tags -->
        <b-form-group label="Tags">
          <vue-autosuggest
            :suggestions="assetAdd.tagOptions"
            :limit="5"
            v-model="assetAdd.tagName"
            id="autosuggest__input"
            :input-props="assetAdd.tagInputProps"
            @input="getAssetTagsBySearchPhraseAndSetData"
            @selected="onTagSelected"
            :get-suggestion-value="getTagSuggestionValue"
          >
            <template
              slot-scope="{ suggestion }"
              style="display: flex; align-items: center"
            >
              <span style="{ display: 'flex', color: 'navyblue'}">{{
                suggestion.item.title
              }}</span>
            </template>
          </vue-autosuggest>
          <div
            class="
              d-flex
              flex-wrap
              align-items-center
              justify-content-start
              mt-75
            "
          >
            <template v-for="(value, i) in assetAdd.selectedTags">
              <b-badge :key="i" variant="light-primary" class="mr-1">
                <span>{{ value.tag_title }}</span>
                <feather-icon
                  @click="removeTag(value.tag_title)"
                  icon="XCircleIcon"
                  class="cursor-pointer ml-50 text-danger"
                />
              </b-badge>
            </template>
          </div>
        </b-form-group>

         <b-row class="my-1">
          <!-- Asset Integrity -->
          <b-form-checkbox
            v-model="assetAdd.asset_integrity"
            value="true"
            class="custom-control-primary ml-1"
            name="integrity-radio-group"
            >Asset Integrity</b-form-checkbox
          >

          <!-- Asset Confidentiality -->
          <b-form-checkbox
            v-model="assetAdd.asset_confidentiality"
            name="confidentiality-radio-group"
            value="true"
            class="custom-control-primary ml-2"
            >Asset Confidentiality</b-form-checkbox
          >

          <!-- Asset Availability -->
          <b-form-checkbox
            id="availability-radio-group"
            v-model="assetAdd.asset_availability"
            name="availability-radio-group"
            value="true"
            class="custom-control-primary ml-2"
            >Asset Availability</b-form-checkbox
          >
        </b-row>

        <b-button
          class="mt-2"
          type="submit"
          variant="success"
          :disabled="invalid"
        >
          <feather-icon icon="CheckIcon" class="cursor-pointer mr-50" /> Submit
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BFormFile,
  BBadge,
  BModal,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetMixins from "../../mixins/AssetMixins";
import TagMixins from "@/mixins/TagMixins";
import ResponseMixins from "@/mixins/ResponseMixins";

extend("tag_check", {
  validate(value) {
    if (value.length > 0) {
      return true;
    }
    return false;
  },
  message: "Please choose atleast one tag",
});

export default {
  name: "AssetAddPage",
  components: {
    ToastificationContent,
    BCardCode,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    VueAutosuggest,
    BBadge,
    BModal,
  },
  data() {
    return {
      assetDetails: {
        asset: {},
        assetLocation: "",
        assetType: {},
        assetOwner: {},
        assetClassification: {},
      },
      assetAdd: {
        asset_name: "",
        asset_location: "",
        asset_value: null,
        asset_value_options: [
          { text: "--Select Asset Value--", value: null },
          { text: "Very High", value: 4 },
          { text: "High", value: 3 },
          { text: "Medium", value: 2 },
          { text: "Low", value: 1 },
        ],

        asset_integrity: true,
        integrity_options: [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ],

        asset_availability: true,
        availability_options: [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ],

        asset_confidentiality: false,
        confidentiality_options: [
          { text: "Yes", value: true },
          { text: "No", value: false },
        ],

        asset_type: null,
        assetTypes: [{ value: null, text: "--Select Asset Category--" }],

        asset_owner: null,
        assetOwners: [{ value: null, text: "--Select Asset Owner--" }],

        asset_classification: null,
        assetClassifications: [
          { value: null, text: "--Select Classification--" },
        ],

        currentSystemGroup: null,
        systemGroupSuggestions: [],
        selectedSystemGroups: [],
        systemGroupInputProps: {
          class: "form-control",
          placeholder: "Search & Select System Groups..",
        },

        selectedTags: [],
        tagOptions: [],
        tagName: "",
        tagInputProps: {
          class: "form-control",
          placeholder: "Search & Select Tags..",
        },
      },
      createNewSystemGroup: {
        title: "",
        description: "",
      },
    };
  },
  mounted: function () {
    this.load();
  },
  mixins: [AssetMixins, TagMixins, ResponseMixins],
  methods: {
    load() {
      this.getAllAssetTypesAndSetOptions();
      this.getAllAssetClassificationsAndSetOptions();
      this.getAllAssetOwnersAndSetOptions();
    },

    showModal() {
      this.$refs["new_asset_modal"].show();
    },
    hideModal() {
      this.$refs["new_asset_modal"].hide();
    },

    onAddNewButtonClick() {
      this.addNewAsset(this.assetAdd)
        .then((res) => {
          this.$router.push({ name: "assets" });
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response.status === 422) {
            this.$refs.asset_form.setErrors(err.response.data.errors);
          }
          if (err.response.status === 500) {
            this.assetAdd.selectedTags = [];
          }
        });
    },
    getAllAssetTypesAndSetOptions() {
      this.getAssetTypes()
        .then((res) => {
          let assettypes = res.data.data;
          for (let i = 0; i < assettypes.length; i++) {
            this.assetAdd.assetTypes.push({
              value: assettypes[i]._id,
              text: assettypes[i].name,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getAllAssetClassificationsAndSetOptions() {
      this.getAssetClassifications()
        .then((res) => {
          let classifications = res.data.data;
          for (let i = 0; i < classifications.length; i++) {
            this.assetAdd.assetClassifications.push({
              value: classifications[i]._id,
              text: classifications[i].title,
            });
          }
        })
        .catch((err) => {
          consol.log(err);
        });
    },
    getAllAssetOwnersAndSetOptions() {
      this.getAssetOwners()
        .then((res) => {
          let owners = res.data.data;
          for (let i = 0; i < owners.length; i++) {
            this.assetAdd.assetOwners.push({
              value: owners[i].user_id,
              text: `${owners[i].firstname} ${owners[i].lastname}`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAssetTagsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getAssetTagsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.assetAdd.tagOptions = [];
              const newTags = res.data.data.data;
              if (newTags.length == 0) {
                newTags.push({ title: searchPhrase, is_new: true });
              }
              this.assetAdd.tagOptions.push({ name: "tags", data: newTags });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.assetAdd.tagOptions = [];
      }
    },
    onTagSelected(val) {
      console.log("val", val);
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.assetAdd.selectedTags.findIndex(
          (tag) => tag.tag_title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.assetAdd.tagName = "";
          this.assetAdd.selectedTags.push({
            tag_id: selectedVal._id,
            tag_title: selectedVal.title,
            is_new: selectedVal._id ? false : true,
          });
        }
      }
    },
    removeTag(tagName) {
      let newSelectedTags = this.assetAdd.selectedTags.filter(
        (tag) => tag.tag_title !== tagName
      );
      this.assetAdd.selectedTags = newSelectedTags;
    },

    getTagSuggestionValue(suggestion) {
      return suggestion.item.title;
    },

    getSystemGroupsBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getSystemGroupsBySearchPhrase(searchPhrase)
            .then((res) => {
              this.assetAdd.systemGroupSuggestions = [];
              const newSystemGroups = res.data.data.data;
              if (newSystemGroups.length !== 0) {
                this.assetAdd.systemGroupSuggestions.push({
                  name: "systemGroups",
                  data: newSystemGroups,
                });
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.assetAdd.tagOptions = [];
      }
    },

    handleNewSystemGroupSubmit() {
      this.addNewSystemGroup(this.createNewSystemGroup)
        .then((res) => {
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "A New System Group has been created.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.assetAdd.selectedSystemGroups.push(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          this.hideModal();
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    onSystemGroupSelected(val) {
      if (val) {
        const selectedVal = val.item;
        const tagIndex = this.assetAdd.selectedSystemGroups.findIndex(
          (tag) => tag.title === selectedVal.title
        );
        if (tagIndex === -1) {
          this.assetAdd.currentSystemGroup = "";
          this.assetAdd.selectedSystemGroups.push(selectedVal);
        }
      }
    },
    removeSystemGroup(title) {
      let newSelectedSystemGroups = this.assetAdd.selectedSystemGroups.filter(
        (g) => g.title !== title
      );
      this.assetAdd.selectedSystemGroups = newSelectedSystemGroups;
    },
    getSystemGroupSuggestionValue(suggestion) {
      return suggestion.item.title;
    },
  },
};
</script>